<template>
    <div class="wrapper_main">
        <main class="content" :key="updateKey">
            <div class="content__header">
                <div class="content__header-left">
                    <h1 class="heading-page-h1">{{ $translate('transaction-history') }}</h1>
                </div>
                <div class="content__header-right">
                    <BluePlusButton to="create-dispatch">{{ $translate('create-a-dispatch') }}</BluePlusButton>
                </div>
            </div>

            <TableAdvanced 
                class="td_capitalize"
                v-if="sourceData.length && products.length"
                v-model="sortCol" 
                :rows="filteredRows" 
                :columns="columns" 
                :isCheckedAll="isCheckedAll"
                :checkedInputs="checkedInputs"
                :sortFieldsForDropdown="sortFieldsForDropdown" 
                @toggleAllCheckedInputs="toggleAllCheckedInputs"
            >
                <template v-slot:filters>
                    <form action="" class="filter-options__search" style="margin-right: auto;">
                        <input type="search" class="input bg-left search-icon" placeholder="Search" v-model="rowsFilters.search" />
                    </form>
                    <!-- <div class="filter-options__items flex-x-wrap flex-auto">
                        <Dropdown :options="['Delivery Dispatch', 'Delivery Dispatch 2', 'Delivery Dispatch 3']" :current="filters.type" name="type" @change="updateFilterData" />
                        <DatePicker v-model="filters.startDate" />
                        <TimePicker v-model="filters.timeStart" />
                        <DatePicker v-model="filters.endDate" />
                        <TimePicker v-model="filters.timeEnd" />
                    </div> -->
                </template>
                <template v-slot:row="slotData">
                    <template v-if="getProductData(slotData.r.batches[0].products[0])">
                        <td>{{ getProductData(slotData.r.batches[0].products[0]).title }}</td>
                        <td>{{ getProductData(slotData.r.batches[0].products[0]).quantity }}/{{ getProductData(slotData.r.batches[0].products[0]).units }}</td>
                    </template>
                    <template v-else>
                        <td>-</td>
                        <td>-</td>
                    </template>
                    <td>{{ slotData.r.batches[0].id }}</td>
                    <td>{{ slotData.r.dispatchID }}</td>
                    <td>{{ convertDate(+slotData.r.companiesStatus.find(itemF => itemF.receive_date).receive_date) }}</td>
                    <!-- <td>{{ new Date(+slotData.r.companiesStatus.find(itemF => itemF.receive_date).receive_date).toDateString() }}</td> -->
                    <td>{{ slotData.r.companiesStatus.find(itemF => itemF.receive_date).type }}</td>
                    <td>
                        <!-- {{ slotData.r.contract_conditions.length }} -->
                        <span class="condition_status" :class="[smartConditionsStatus.includes(slotData.r.id) ? 'green' : 'red']"></span>
                    </td>
                    <td>
                        {{ $translate('sold') }}
                        <!-- {{ getProductData(slotData.r.batches[0].products[0]) ? getProductData(slotData.r.batches[0].products[0]).status : '-' }} -->
                    </td>
                    <td class="position-stiky"></td>
                </template>
            </TableAdvanced>
        </main>
        <Pagination v-model="filteredRows" :items="rawSource" />
    </div>
</template>

<script>
import Base from "@/components/base";
import Popups from "@/components/popups";
import Buttons from "@/components/buttons";
import { mixTables,mixDeleteItem } from "@/mixins";
import { API } from 'aws-amplify';
import { listProducts, getDevice } from '@/graphql/queries';
import { devicesDataCategories } from '@/config';

export default {
    name: "TransactionHistory",
    mixins:[mixTables],
    components: {
        ...Base,
        ...Popups,
        ...Buttons,
    },
    data() {
        return {
            filters: {
                type: "All Types",
                startDate: null,
                endDate: null,
                timeStart: null,
                timeEnd: null,
            },
            sortField: "Date",

            source:"dispatches",
            columns: [
                { name: "Product Name", filter: "productName" },
                { name: "Quantity/units", filter: "" },
                { name: "Batch id", filter: "" },
                { name: "Name of the dispatch", filter: "" },
                { name: "Date", filter: "" },
                { name: "Type", filter: "" },
                { name: "Conditions", filter: "" },
                { name: "Status of the product", filter: "" },
            ],
            products: [],
            calculatedFields:[
            //     {
            //         filter: 'batchesCount',
            //         path: ['batches'],
            //         calcFunction: (bathces) => {          
            //             return bathces.length
            //         },
            //     },
            //     {
            //         filter: 'ownerFullname',
            //         path: ['owner'],
            //         calcFunction: (owner) => {          
            //             return owner.fullname||"none"
            //         },
            //     },
            //     {
            //         filter: 'localStatus',
            //         path: ['companiesStatus'],
            //         calcFunction: (arr) => {          
            //             return arr.find(obj=>obj.id==this.companyData.id)?.status||'null'
            //         },
            //     }
            ],
            returnedDispatches: [],
            updateKey: 0,
            searchField:"dispatchID",
            smartConditionsStatus: [],
            categories: devicesDataCategories,
        };
    },
    computed: {
        companyData(){
            return this.$store.state.companyData || {}
        },
        sourceData(){
            return this.returnedDispatches;
        },
        sortFieldsForDropdown(){
            return  [
                { name: "Date creation", filter: "createdAt" },
                { name: "Update At", filter: "updatedAt" },
                { name: "Name", filter: "dispatchID" },
            ]
        },
        statusOptions(){
            return [{name:"All statuses",id:"all"},...statuses.map(s=>{
                return {
                    name:s,
                    id:s
                }
            })]
        },
    },
    watch: {
        rows: {
            handler(newValue, oldValue) {
                this.checkSmartConditions();
            },
            deep: true
        }
    },
    methods: {
        // updateFilterData(ev) {
        //     const target = ev.target;
        //     const value = target.value;
        //     const name = target.name;
        //     this.filters[name] = value;
        // },
        // updateSortField(ev) {
        //     const target = ev.target;
        //     const value = target.value;
        //     this.sortField = value;
        // },
        convertDate(dateNumber){
            function numbNoZero(numb){
                if(String(numb).length < 2){
                    return '0'+String(numb)
                } else {
                    return numb;
                }
            }

            let date = new Date(dateNumber);

            return `${numbNoZero(date.getDate())}.${numbNoZero(date.getMonth())}.${date.getFullYear()}`
        },
        showItemConditionStatus(item, type){
            let findedconditions = item.contract_conditions.find(conditionItem => conditionItem.condition_type === type);
            if(findedconditions){
                return findedconditions.value ? findedconditions.value : 'n/a';
            } else {
                return 'n/a';
            }
        },
        getProductData(id){
            return this.products.find(item => item.id === id);
        },
        async getTransactionsData(){
            if(!this.$store.state.dispatches.length){
                setTimeout(() => {
                    this.getTransactionsData();
                    return false;
                }, 2000);
            } else {
                this.$store.state.dispatches.forEach(element => {
                    element.companiesStatus.forEach(cStatus => {
                        if(
                            (cStatus.receive_date || (cStatus.status === 'completed' && 
                            cStatus.receive_date)) && (!this.returnedDispatches.length ||
                            !this.returnedDispatches.map(mItem => mItem.id).includes(element.id))
                        ){
                            this.returnedDispatches.push(element);
                        }
                    });
                });

                this.sourceData.forEach(async element => {
                    let requests = element.companies.items.map(async cItem => {
                        const filter = { companyProductsId: { eq: cItem.companyID } }
                        return await API.graphql({
                            query: listProducts,
                            variables: {
                                filter
                            }
                        }).then(res => res.data.listProducts.items);
                    });
                    await Promise.all(requests).then(res => {
                        this.products.push(res.flat());
                    });
                });

                setTimeout(() => {
                    this.products = this.products.flat();
                    this.updateKey++;
                }, 1000);
            }
        },
        async checkSmartConditions(){
            this.smartConditionsStatus = [];

            this.rows.forEach(async row => {
                let findSmartCondition = row.contract_conditions.find(item => item.condition_type === "smart");
                if(findSmartCondition){
                    const device = await API.graphql({
                        query: getDevice,
                        variables: {
                            id: findSmartCondition.select_device,
                        },
                    }).then((res) => res.data.getDevice);

                    if(device && device.config && device.config.device_id && this.categories[device.category]) {

                        let lastValueData = {};

                        if(device.category === 'carbon-dioxide' || device.category === 'phosphine'){
                            let realDeviceData = await this.$provider.centaurAPI.getRealDeviceData(device.config.device_id);

                            if(realDeviceData && realDeviceData.length) {
                                let filteredByCategoryData = realDeviceData.filter(item => item.type === this.categories[device.category].category);
                                lastValueData = filteredByCategoryData[filteredByCategoryData.length-1];
                            }
                        } else {
                            //console.log(findSmartCondition);
                            let splitMinDate = findSmartCondition.from_date.split('.');
                            let splitMaxDate = findSmartCondition.to_date.split('.');

                            let timeDiff = Math.abs(new Date(`${ splitMinDate[2]+'-'+splitMinDate[1]+'-'+splitMinDate[0] }T${findSmartCondition.from_time}:00.000Z`).getTime() - new Date(`${ splitMaxDate[2]+'-'+splitMaxDate[1]+'-'+splitMaxDate[0] }T${findSmartCondition.to_time}:00.000Z`).getTime());
                            let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

                            let dataFromxFarm = await this.$provider.xFarm.getDeviceData(
                                device.config.device_id,
                                {
                                    from: new Date(`${ splitMinDate[2]+'-'+splitMinDate[1]+'-'+splitMinDate[0] }T${findSmartCondition.from_time}:00.000Z`).toISOString(),
                                    periodTo: `${ diffDays }d`
                                }
                            );

                            let valueVariableName = null;

                            if(findSmartCondition.parameter_obeserved === 'temperature' || findSmartCondition.parameter_obeserved === 'temperature-humidity' || findSmartCondition.parameter_obeserved === 'weather-station-xsense') valueVariableName = 'at';
                            if(findSmartCondition.parameter_obeserved === 'leaf-moisture') valueVariableName = 'lw';
                            if(findSmartCondition.parameter_obeserved === 'soil-moisture') valueVariableName = 'sm1';

                            let realDeviceData = dataFromxFarm.map(item => {
                                return {
                                    "timestamp": item.time,
                                    "type": this.categories[findSmartCondition.parameter_obeserved].category,
                                    "value": item[valueVariableName],
                                    "deviceId": item.did,
                                    "fullData": item
                                }
                            });

                            if(realDeviceData && realDeviceData.length) {
                                lastValueData = realDeviceData[realDeviceData.length-1];
                            }
                        }

                        if(lastValueData.value){
                            let statusCondition = false;
                            
                            switch (findSmartCondition.operator) {
                                case 'equal':
                                    if(+lastValueData.value === +findSmartCondition.value) statusCondition = true;
                                    break;
                                case 'not_equal':
                                    if(+lastValueData.value !== +findSmartCondition.value) statusCondition = true;
                                    break;
                                case 'greater_than':
                                    if(+lastValueData.value > +findSmartCondition.value) statusCondition = true;
                                    break;
                                case 'less_than':
                                    if(+lastValueData.value < +findSmartCondition.value) statusCondition = true;
                                    break;
                                case 'equal_or_grater_than':
                                    if((+lastValueData.value === +findSmartCondition.value) || (+lastValueData.value > +findSmartCondition.value)) statusCondition = true;
                                    break;
                                case 'equal_or_less_then':
                                    if((+lastValueData.value === +findSmartCondition.value) || (+lastValueData.value < +findSmartCondition.value)) statusCondition = true;
                                    break;
                                default:
                                    break;
                            }

                            if(statusCondition){
                                this.smartConditionsStatus.push(row.id);
                            }
                        }
                    }
                }
            });
        },
    },
    mounted(){
       this.getTransactionsData();
    }
};
</script>